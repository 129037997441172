import "./stylesheets/registrations.scss";
console.log('registrations js');

(function () {
  'use strict'
console.log('validation________',document.querySelectorAll('.needs-validation'));

  // Fetch all the forms we want to apply custom Bootstrap validation styles to
  var forms = document.querySelectorAll('.needs-validation');

  // Loop over them and prevent submission
  Array.prototype.slice.call(forms)
    .forEach(function (form) {
      console.log('this',form);

      // form.addEventListener('onchange', function (event) {
      //   console.log('+++++++++++', e);
      //   if (!form.checkValidity()) {
      //     event.preventDefault()
      //     event.stopPropagation()

      //   }


      //   form.classList.add('was-validated')
      // }, false)

      form.addEventListener('submit', function (event) {
        if (!form.checkValidity()) {
          event.preventDefault()
          event.stopPropagation()
        }

        form.classList.add('was-validated')
      }, false)
    })
})()
